import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import Logo from 'assets/videoms-logo.png';
import HeaderUserDropdown from 'common/HeaderUserDropdown';

const VidTechHeader = ({ userRole }) => {
  return (
    <header className="shadow-sm">
      <Navbar className="py-2 pr-0 bg-white" expand="md">
        <div>
          <NavbarBrand
            href="https://videoms.com/"
            target="_blank"
            tag="a"
            className="d-inline-flex flex-column"
          >
            <img src={Logo} alt="Logo" width="80" />
          </NavbarBrand>
        </div>
        {userRole && (
          <div className="ml-auto position-relative">
            <HeaderUserDropdown />
          </div>
        )}
      </Navbar>
    </header>
  );
};

export default VidTechHeader;
