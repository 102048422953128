import React from 'react';
import Logo from 'assets/videoms-logo.png';
import { Navbar, NavbarBrand } from 'reactstrap';

const Header = () => {
  return (
    <div>
      <div>
        <Navbar className="py-2 pr-0" expand="md">
          <div>
            <NavbarBrand
              href="https://videoms.com/"
              target="_blank"
              tag="a"
              className="d-inline-flex flex-column"
            >
              <img src={Logo} alt="Logo" width="80" />
            </NavbarBrand>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
