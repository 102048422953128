import flashIcon from 'assets/flash-icon.svg';

export const APP_TOKEN = 'videoms-token';
export const PROJECT_NAME = 'project-name';

export const DRAWER_HEADER_ICONS = {
  ATTENTION_DRAWER: flashIcon,
};

export const PROJECT_DETAIL_PAGE_HEADINGS = {
  ACTIVE: {
    label: 'Back to Active Projects',
    slug: 'active-projects',
  },
  NEED_MY_ATTENTION: {
    label: 'Back to Need My Attention',
    slug: 'need-my-attention',
  },
  COMPLETED: {
    label: 'Back to Completed',
    slug: 'completed',
  },
};

export const AUCTION = 'AUCTION';

export const ELITE = 'ELITE';

export const SILVER = 'SILVER';

export const BRONZE = 'BRONZE';

export const ATTENTION_POPOVER = {
  status: false,
  popoverID: null,
  attentionData: null,
  planName: null,
};

export const CREDENTIAL_STATUS = {
  invalidValidEmail: false,
  invalidValidPassword: false,
};

export const INVALID_EMAIL = 'Invalid email id!';

export const INVALID_PASSWORD = 'Invalid password!';

export const COMMENT_SOURCE = {
  VIDEO_PLAYER: 'video_player',
  PORTAL: 'portal',
};

export const ANNOTATION_TOOLS = {
  PEN: 'pen',
  LINE: 'line',
  RECT: 'rect',
  ARROW: 'arrow',
};

export const NOT_SPECIFIED = 'Not Specified';
export const NOT_ASSIGNED = 'Not Assigned';
export const SLACK_LINK = 'https://slack.com/signin#/signin';
export const SUB_DOMAIN = 'testvidpitch';

// Add Folder name here that will subfolder not assets directly
export const FOLDER_NAMES_TO_EXCLUDE = [
  'Custom Shots OR B Roll',
  'Original Videos',
  'Original Images',
  'Project Documents',
  'Project Data',
];

// Add Folder, subFolder names here that will render in subFolder component
export const FOLDERS_WITH_CHILDREN = {
  'Original Images': 'originalImages',
  'Original Videos': 'originalVideos',
  'Project Documents': 'projectDocuments',
  'Project Data': 'projectData',
  'Custom Shots OR B Roll': 'customShotsOrBRoll',
  // Add top folder name here too
  'Project Inputs': 'projectInputs',
  'Aerial Imagery': 'aerialImagery',
};

// Add Folders here which has folders and files together
export const FOLDERS_FILES_TOGETHER = {
  'Project Data': ['Property Boundary'],
  // 'Project Documents': ['Company OR Broker Logo'], //  test example
};

export const SKILLS = [
  { id: 'Premium', label: 'Premium' },
  { id: 'Standard', label: 'Standard' },
  { id: 'Vidspection', label: 'Vidspection' },
  { id: 'Basic Models', label: 'Basic Models' },
  { id: 'Premium Plus', label: 'Premium Plus' },
  { id: 'Photorealistic Models', label: 'Photorealistic Models' },
];
