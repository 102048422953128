import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as htmlToImage from 'html-to-image';
import crypto from 'crypto';
import Picker from 'emoji-picker-react';
import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from 'reactstrap';
import {
  convertSecondsToTimeCode,
  removeScreenShots,
  timeFormator,
} from 'common/VideoPlayer/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faCircle,
  faSmile,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import reactangle from 'assets/icons/Rectangle16091.svg';
import circle from 'assets/icons/circle.svg';
import freeDraw from 'assets/icons/freedraw.svg';
import line from 'assets/icons/line-chart-line.svg';
import arrow from 'assets/icons/cursor-fill.svg';
import undo from 'assets/icons/arrow-go-back-line.svg';
import redo from 'assets/icons/arrow-go-forward-line.svg';
import thin from 'assets/icons/edit-fill.svg';
import fat from 'assets/icons/mark-pen-fill.svg';
import triangle from 'assets/icons/triangle.svg';
import polygon from 'assets/icons/polygon.svg';
import hexagon from 'assets/icons/hexagon.svg';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_VIDEO_PLAYER_COMMENT,
  GET_S3_URL,
  VIDEO_PLAYER_CHAPTER_TEXT_CHANGES_BRONZE,
} from 'common/VideoPlayer/videoPlayerGraphQL';
import Avatar from 'common/Avatar';
import {
  BRONZE_CHAPTER_VIDEO_TIMESTAMP,
  BRONZE_REVIEW_COMPLETE_ROLES,
  BRONZE_TEXT_COMMENT_ERROR_MESSAGE,
  SECTION_TASKS,
} from 'common/VideoPlayer/constants';
import { COMMENT_SOURCE } from 'constants/appConstants';
import { ROLES } from 'constants/role';
import { authContext } from 'contexts/AuthContext';
import {
  crexiBronzeCurrentChapter,
  crexiBronzeVideoTextChange,
} from 'pages/CrexiBronze/CrexiBronzeAction';
import LeaveCommentModal from './components/LeaveCommentModal';
import { editorProjectDetails } from 'pages/EditorPortal/EditorPortalActions';

let commentUserDetails;
let fileData;

const CreateComment = ({
  showAnnotationBoard,
  setShowAnnotationBoard,
  setVideoPlayerDetails,
  videoElement,
  playerState,
  screenshotDivElement,
  commentScreenshotElement,
  playerData,
  refetch,
  eleWithScreenshotAndAnnotation,
  setAnnotationDetails,
  annotationDetails,
  setAnnotationsList,
  annotationsList,
  setCommentLoading,
  isImage,
  assetId,
  reviewCompleted,
}) => {
  const videoElementRef = videoElement.current;
  const { isBronze } = playerState;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emojiDropdownOpen, setEmojiDropdownOpen] = useState(false);
  const [videoComment, setVideoComment] = useState('');
  const [backwordForwordData, setBackwordForwordData] = useState([]);
  const [hasCommentClicked, setHasCommentClicked] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useContext(authContext);

  const [getAwsS3PresignedUrl, { data, loading }] = useLazyQuery(GET_S3_URL);
  const {
    getReviewLinkDetails: {
      assetCommentDetails: {
        brokerReviewStatus,
        project_folder: projectFolder,
        taskName,
      },
    },
  } = playerData;



  const [addBrokerCommentsOnReviewAsset] = useMutation(
    ADD_VIDEO_PLAYER_COMMENT,
    {
      onCompleted: () => {
        refetch();
        setShowAnnotationBoard(false);
      },
    }
  );

  const [videoPlayerTextChangesBronze] = useMutation(
    VIDEO_PLAYER_CHAPTER_TEXT_CHANGES_BRONZE,
    {
      onCompleted: (resp) => {
        if (resp?.chapterTextChanges?.status) {
          dispatch(
            crexiBronzeVideoTextChange({
              isVideoTextChange: true,
            })
          );
          history.push(`/crexi-bronze/${projectFolder?.project?.id}`);
        } else {
          toast.error(BRONZE_TEXT_COMMENT_ERROR_MESSAGE);
        }
      },
      onError: () => {
        toast.error(BRONZE_TEXT_COMMENT_ERROR_MESSAGE);
      },
    }
  );

  const toggleAddCommentModal = (status, userDetails = null) => {
    setVideoPlayerDetails({
      ...playerState,
      showAddCommentModal: status,
      userDetails,
    });
  };

  useEffect(() => {
    if (data && !loading) {
      const getPreSignedURL = async () => {
        if (data.getAwsS3PresignedUrl) {
          const { getAwsS3PresignedUrl } = data;

          const uploadResp = await axios.put(getAwsS3PresignedUrl, fileData, {
            headers: { 'Content-Type': fileData.type },
          });

          if (uploadResp) {
            const timeCodeVal = convertSecondsToTimeCode(
              videoElement.current.currentTime,
              20
            );
            const randomString = crypto.randomBytes(20).toString('hex');
            const { userDetails } = playerState;
            const userData = commentUserDetails || userDetails;
            const s3Url = getAwsS3PresignedUrl.slice(
              0,
              getAwsS3PresignedUrl.lastIndexOf('?')
            );

            const commentsObject = {
              commentId: randomString,
              commentTime: !isImage ? timeCodeVal : '00:00:00:00',
              commentScreenShot: s3Url || '',
              commentText: videoComment,
              commentCreatedAt: new Date(),
              commentUser: userData ? userData.firstName : null,
              commentUserId: userData
                ? `${userData?.id ?? userData?.userId}`
                : null,
              commentUpdatedAt: new Date(),
              commentcompletedAt: null,
              assetId,
              commentSource: COMMENT_SOURCE.VIDEO_PLAYER,
              annotationCords: null,
              parentId: null,
              toEditor: false,
            };
            addBrokerCommentsOnReviewAsset({
              variables: commentsObject,
            });
          }
        }
      };
      getPreSignedURL();
    }
  }, [data, loading]);

  const onEmojiClick = (event, emojiObject) => {
    const commentString = videoComment.concat(emojiObject.emoji);
    setVideoComment(commentString);
  };

  const toggleEmoji = (e) => {
    setEmojiDropdownOpen(!emojiDropdownOpen);
  };

  const toggleAnnotation = (e) => {
    setDropdownOpen(!dropdownOpen);
    if (!annotationDetails.type) {
      setShowAnnotationBoard(true);
    }
    setAnnotationDetails({
      ...annotationDetails,
      type: annotationDetails.type || 'freeDrawing',
    });
  };

  const anotationsAttributes = [
    {
      id: 1,
      type: 'freeDrawing',
      icon: freeDraw,
      name: 'Freeform',
      activeClass: '',
    },
    {
      id: 2,
      type: 'rect',
      name: 'Rectangle',
      icon: reactangle,
      activeClass: '',
    },

    {
      id: 3,
      type: 'circle',
      icon: circle,
      name: 'Circle',
      activeClass: '',
    },
    {
      id: 4,
      type: 'line',
      icon: line,
      name: 'Line',
      activeClass: '',
    },
    {
      id: 5,
      type: 'arrow',
      icon: arrow,
      name: 'Arrow',
      activeClass: '',
    },
    {
      id: 6,
      type: 'hexagon',
      icon: hexagon,
      name: 'Hexagon',
      activeClass: '',
    },
    {
      id: 7,
      type: 'pentagon',
      icon: polygon,
      name: 'Pentagon',
      activeClass: '',
    },
    {
      id: 8,
      type: 'triangle',
      icon: triangle,
      name: 'Triangle',
      activeClass: '',
    },
  ];

  const anotationsColor = [
    {
      id: 1,
      icon: faCircle,
      color: '#C92020',
      type: '#C92020',
      activeClass: '',
    },
    {
      id: 2,
      icon: faCircle,
      color: '#41B5F2',
      type: '#41B5F2',
      activeClass: '',
    },
    {
      id: 3,
      icon: faCircle,
      color: '#76D938',
      type: '#76D938',
      activeClass: '',
    },
    {
      id: 4,
      icon: faCircle,
      color: '#F4DB22',
      type: '#F4DB22',
      activeClass: '',
    },
    {
      id: 5,
      icon: faCircle,
      color: '#EB9929',
      type: '#EB9929',
      activeClass: '',
    },
  ];

  const uploadFile = async (file, uploadingPath, commentUserData = null) => {
    const trimedUploadingPath =
      uploadingPath && uploadingPath.slice(0, uploadingPath.lastIndexOf('/'));

    fileData = file;
    commentUserDetails = commentUserData;

    await getAwsS3PresignedUrl({
      variables: {
        location: trimedUploadingPath,
        fileName: file.name,
        contentType: file.type,
      },
    });
  };
  // take screenhot of video tag
  const captureVideoScreen = () => {
    const screenshotDiv = screenshotDivElement.current;
    const w = videoElementRef.offsetWidth;
    const h = videoElementRef.offsetHeight;
    const canvas = window.document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    canvas.id = 'videoPlayerScreenCanvas';
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(
      videoElementRef,
      0,
      0,
      parseInt(canvas.width, 10),
      parseInt(canvas.height, 10)
    );
    screenshotDiv.appendChild(canvas);
  };

  useEffect(() => {
    if (showAnnotationBoard) {
      setVideoPlayerDetails({
        ...playerState,
        imageBase64: null,
        isPlaying: false,
      });
      // call method to take screenshot of current video timestamp
      captureVideoScreen();
    } else {
      setBackwordForwordData([]);
      setAnnotationsList([]);
      setVideoComment('');
      removeScreenShots();
      setAnnotationDetails({
        annotationStrockWidth: 5,
        annotationStrockColor: '#C92020',
        type: '',
      });
    }
  }, [showAnnotationBoard]);

  const handleSendCommentActions = async (commentUserData = null) => {
    if (
      (playerData &&
        playerData.getReviewLinkDetails &&
        playerData.getReviewLinkDetails.assetCommentDetails &&
        annotationsList.length) ||
      videoComment
    ) {
      const { uploadingPath, id } =
        playerData.getReviewLinkDetails.assetCommentDetails;
      const videoScreenDiv = eleWithScreenshotAndAnnotation.current;
      setCommentLoading(true);
      htmlToImage
        .toJpeg(videoScreenDiv, {
          width: videoElementRef.offsetWidth,
          height: videoElementRef.offsetHeight,
        })
        .then(async (dataUrl) => {
          if (dataUrl) {
            const arr = dataUrl.split(',');
            const bstr = arr.length ? window.atob(arr[1]) : '';
            const strLength = bstr.length;
            const u8arr = new Uint8Array(strLength);

            for (let i = strLength; i !== 0; i -= 1) {
              u8arr[i] = bstr.charCodeAt(i);
            }

            u8arr[0] = bstr.charCodeAt(0);
            const randomTime = Math.floor(Date.now());

            const file = new File([u8arr], `${randomTime}.jpeg`, {
              type: 'image/jpeg',
              lastModified: new Date(),
            });
            uploadFile(file, uploadingPath, commentUserData);
          }
        })
        .catch((error) => {
          setCommentLoading(false);
          toast.error('Something went wrong.');
        });
    }
  };

  const toggleHasCommentClicked = (status) => {
    if (isBronze) {
      handleSendCommentActions();
    }
    if (playerState.showAddCommentModal) {
      setHasCommentClicked(status);
    } else {
      handleSendCommentActions();
    }
  };

  const handleBackwardClick = () => {
    const lastIndexLength = annotationsList.length - 1;
    if (lastIndexLength >= 0) {
      const data = annotationsList[lastIndexLength];
      backwordForwordData.push(data);
      setBackwordForwordData(backwordForwordData);
      const filterdAnnotations = annotationsList.filter((itm, index) => {
        return data.key !== itm.key;
      });
      setAnnotationsList(filterdAnnotations);
    }
  };

  const handleForwardClick = () => {
    const lastIndexLength = backwordForwordData.length - 1;
    if (lastIndexLength >= 0) {
      const data = backwordForwordData[lastIndexLength];
      backwordForwordData.pop();
      setAnnotationsList([...annotationsList, data]);
      setBackwordForwordData(backwordForwordData);
    }
  };

  const handleBronzeTextChange = () => {
    setShowAnnotationBoard(true);
    const projectId = projectFolder?.project?.id ?? null;
    const currentTime = videoElement?.current?.currentTime;
    const matchedChapterObj = BRONZE_CHAPTER_VIDEO_TIMESTAMP.find(
      (chapter) => currentTime >= chapter.timeStamp
    );

    if (matchedChapterObj && isBronze && projectId) {
      dispatch(
        crexiBronzeCurrentChapter({
          currentChapter: matchedChapterObj.chapter,
        })
      );
      dispatch(
        editorProjectDetails({
          propertyDetail: {
            name: projectFolder?.project?.asset?.name,
            crexiUrl: '',
          },
        })
      );
      try {
        videoPlayerTextChangesBronze({
          variables: {
            projectId,
          },
        });
      } catch (err) {
        console.log('err');
        toast.error(BRONZE_TEXT_COMMENT_ERROR_MESSAGE);
      }
    }
  };

  return (
    <div className="comment-input-box">
      <div className="comment-input d-flex align-items-center">
        <Avatar
          userName={
            playerState.userDetails && playerState.userDetails.firstName
          }
        />
        <span>
          <Badge
            color="primary"
            pill
            className="px-3 py-2 font-size-12 text-bg-secondary mx-2"
          >
            {!isImage && !playerState.isPlaying
              ? timeFormator(
                videoElement.current ? videoElement.current.currentTime : 0,
                playerState.formatorType
              )
              : null}
          </Badge>
        </span>
        <Input
          type="text"
          placeholder="Leave your comment here"
          className="c-input"
          value={videoComment}
          onFocus={() => setShowAnnotationBoard(true)}
          onChange={(e) => setVideoComment(e.target.value)}
        // style={{ width: isBronze ? '71%' : '' }}
        />

        <div
          className="d-flex justify-content-end"
          style={{ width: isBronze ? '55%' : '50%' }}
        >
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggleAnnotation}
            className="mr-2"
            size="sm"
            color="primary"
            direction="up"
          >
            <DropdownToggle>
              <FontAwesomeIcon icon={faPencilAlt} className="text-white" />
            </DropdownToggle>
            <DropdownMenu className="annotation-menu">
              <div className="d-flex">
                <div className="mb-4">
                  <h6 className="mb-3 font-size-14">Colour</h6>
                  <div className="d-flex">
                    {anotationsColor.map((annot) => {
                      return (
                        <DropdownItem
                          toggle={false}
                          key={annot.id}
                          className={
                            annotationDetails.annotationStrockColor ===
                              annot.color
                              ? 'p-0 activeAnnotations'
                              : 'p-0'
                          }
                          onClick={() =>
                            setAnnotationDetails({
                              ...annotationDetails,
                              annotationStrockColor: annot.color,
                            })
                          }
                          style={{ minWidth: '20px', width: '20px' }}
                        >
                          <FontAwesomeIcon
                            icon={annot.icon}
                            style={{ color: annot.color }}
                          />
                          <br />
                          <span className="font-size-12">{annot.name}</span>
                        </DropdownItem>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mb-4 d-flex">
                <div className="mr-5">
                  <h6 className="font-size-14">Line Thickness</h6>
                  <div className="d-flex align-items-center">
                    <DropdownItem
                      toggle={false}
                      className={
                        annotationDetails.annotationStrockWidth === 5
                          ? 'activeAnnotations'
                          : ''
                      }
                      onClick={() =>
                        setAnnotationDetails({
                          ...annotationDetails,
                          annotationStrockWidth: 5,
                        })
                      }
                    >
                      <img
                        src={thin}
                        alt=""
                        style={{
                          width: '15px',
                        }}
                      />
                      <br />
                      <span className="font-size-10">Thin</span>
                    </DropdownItem>
                    <DropdownItem
                      toggle={false}
                      className={
                        annotationDetails.annotationStrockWidth === 10
                          ? 'activeAnnotations'
                          : ''
                      }
                      onClick={() =>
                        setAnnotationDetails({
                          ...annotationDetails,
                          annotationStrockWidth: 10,
                        })
                      }
                    >
                      <img
                        src={fat}
                        alt=""
                        style={{
                          width: '15px',
                        }}
                      />
                      <br />

                      <span className="font-size-10">Fat</span>
                    </DropdownItem>
                  </div>
                </div>

                <div className="ml-5 pl-2">
                  <h6 className="mb-2 font-size-14">Actions</h6>
                  <div className="d-flex video-actions">
                    <DropdownItem toggle={false} onClick={handleBackwardClick}>
                      <img
                        src={undo}
                        alt=""
                        style={{
                          width: '15px',
                        }}
                      />
                      <br />
                      <span className="font-size-10">Undo</span>
                    </DropdownItem>
                    <DropdownItem toggle={false} onClick={handleForwardClick}>
                      <img
                        src={redo}
                        alt=""
                        style={{
                          width: '15px',
                        }}
                      />
                      <br />
                      <span className="font-size-10">Redo</span>
                    </DropdownItem>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <h6 className="mb-3 font-size-14">Shapes</h6>

                <div className="d-flex">
                  {anotationsAttributes.map((annot) => {
                    return (
                      <div key={annot.id}>
                        <DropdownItem
                          toggle={false}
                          onClick={() =>
                            setAnnotationDetails({
                              ...annotationDetails,
                              type: annot.type,
                            })
                          }
                          className={
                            annotationDetails.type === annot.type
                              ? 'activeAnnotations'
                              : ''
                          }
                        >
                          <img
                            src={annot.icon}
                            alt=""
                            className=""
                            style={{
                              width: annot.name === 'Circle' ? '15px' : '20px',
                            }}
                          />
                          <br />
                          <span className="font-size-10">{annot.name}</span>
                        </DropdownItem>
                      </div>
                    );
                  })}
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>

          <Dropdown
            isOpen={emojiDropdownOpen}
            toggle={toggleEmoji}
            className="mr-2 comment-emoji"
            size="sm"
            color="primary"
            direction="up"
          >
            <DropdownToggle>
              <FontAwesomeIcon icon={faSmile} className="text-white" />
            </DropdownToggle>
            <DropdownMenu>
              <Picker onEmojiClick={onEmojiClick} />
            </DropdownMenu>
          </Dropdown>
          <span>
            <Button
              size="sm"
              color="secondary"
              onClick={() => toggleHasCommentClicked(true)}
              disabled={auth?.data?.role === ROLES.VIDEOMS_PM ? false :
                [ROLES.VIDEOMS_PILOT, ROLES.VIDEOMS_EDITOR].includes(
                  auth?.data?.role
                ) ||
                !(annotationsList.length || videoComment.trim()) ||
                brokerReviewStatus ||
                reviewCompleted
              }
            >
              {/* Conditional rendering of text in bronze case */}
              {isBronze ? 'Map Comment' : 'Comment'}
            </Button>
          </span>

          {/* Render map comment in case of bronze */}
          {isBronze && (
            <span>
              <Button
                size="sm"
                color="secondary"
                className="ml-2"
                disabled={
                  !(
                    (taskName === SECTION_TASKS.userCommentReview &&
                      projectFolder?.project?.checkoutUserId ===
                      auth?.data?.userId) ||
                    BRONZE_REVIEW_COMPLETE_ROLES[taskName]?.includes(
                      auth?.data?.role
                    )
                  ) ||
                  brokerReviewStatus ||
                  reviewCompleted ||
                  videoComment.trim()
                }
                onClick={() => handleBronzeTextChange()}
              >
                Change Text
              </Button>
            </span>
          )}
        </div>
      </div>
      {!isBronze && hasCommentClicked && (
        <LeaveCommentModal
          assetId={assetId}
          showAddCommentModal={playerState.showAddCommentModal}
          toggleAddCommentModal={toggleAddCommentModal}
          toggleHasCommentClicked={toggleHasCommentClicked}
          handleSendCommentActions={handleSendCommentActions}
        />
      )}
    </div>
  );
};

export default CreateComment;
