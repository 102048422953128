import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, Container } from 'reactstrap';
import { toast } from 'react-toastify';
//
import { PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT } from 'pages/Pilot/PilotPortalGraphQL';
import { parseToInteger } from 'utils/helper';
import { PILOT_PROJECT_ACTIONS } from 'pages/Pilot/constant';

const ProjectAcceptBox = ({ selectProject, isProjectAccepted, refetch }) => {
  const [projectAcceptedByPilot] = useMutation(
    PROJECT_ACCEPTED_BY_EDITOR_OR_PILOT,
    {
      onCompleted: async ({ projectAcceptedByEditorOrPilot }) => {
        await refetch();
        toast.success(projectAcceptedByEditorOrPilot.message);
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );

  const handleAcceptProject = async (acceptedStatus) => {
    try {
      await projectAcceptedByPilot({
        variables: {
          projectId: parseToInteger(selectProject),
          status: acceptedStatus,
          flag: 'PILOT',
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      {!isProjectAccepted && (
        <div className="text-center accept-footer bg-purple-dark text-white py-3">
          <Container>
            <div>
              <p>This is new project, what you want to do?</p>
              <div>
                <Button
                  size="sm"
                  outline
                  color="primary"
                  className="border mr-2 px-4 text-white"
                  onClick={() =>
                    handleAcceptProject(PILOT_PROJECT_ACTIONS.Decline)
                  }
                >
                  Decline
                </Button>
                <Button
                  size="sm"
                  color="secondary"
                  className="bg-white text-dark px-4 border border-white"
                  onClick={() =>
                    handleAcceptProject(PILOT_PROJECT_ACTIONS.Accept)
                  }
                >
                  Accept Project
                </Button>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default ProjectAcceptBox;
