import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Badge, Spinner } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  convertTimecodeToSeconds,
  timeFormator,
} from 'common/VideoPlayer/util';
import Avatar from 'common/Avatar';
import LoadingButton from 'common/LoadingButton';
import { dateTimeFormatter } from 'utils/helper';
import { authContext } from 'contexts/AuthContext';
import ReviewCompleteModal from '../ReviewCompleteModal';
import { VIDEO_PLAYER_REVIEW_COMPLETE } from 'common/VideoPlayer/videoPlayerGraphQL';
import {
  BRONZE_REVIEW_COMPLETE_ROLES,
  SECTION_TASKS,
  UNALLOWED_ROLES_FOR_VIDEO_REVIEW_COMPLETE,
} from 'common/VideoPlayer/constants';

const ListComments = ({
  playerData,
  handleTimeMoveByComment,
  commentLoading,
  playerState,
  reviewCompleted,
  reviewStatus,
  handleReivewChecked,
  setReviewCompleted,
  setShowConfirmationModal,
}) => {
  const commentsRef = useRef();
  const { auth } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewCompleteStatus, setReviewCompleteStatus] = useState(false);

  useEffect(() => {
    if (playerData?.getReviewLinkDetails) {
      commentsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [playerData]);

  const [videoPlayerReviewComplete] = useMutation(
    VIDEO_PLAYER_REVIEW_COMPLETE,
    {
      onCompleted: () => {
        setReviewCompleted(true);
        setReviewCompleteStatus(false);
        setShowConfirmationModal(true);
        setIsLoading(false);
      },
      onError: ({ message }) => {
        toast.error(message);
        setIsLoading(false);
        setReviewCompleted(false);
        setReviewCompleteStatus(false);
      },
    }
  );

  const renderComments = (assetComments) => {
    return assetComments.map((comment) => {
      const {
        id,
        commentCreatedAt,
        commentCompletedAt,
        commentTime,
        commentText,
        commentUser,
      } = comment;
      const timeInSeconds = convertTimecodeToSeconds(
        commentTime || '00:00:00:00',
        20
      );
      return (
        <div key={id} ref={commentsRef}>
          <div className="p-3 d-flex">
            <div>
              <Avatar userName={commentUser} />
            </div>
            <div className="w-100">
              <div className="d-flex justify-content-between">
                <span className="text-capitalize font-weight-bold">
                  {commentUser || 'Guest'}
                </span>
                <span
                  title={dateTimeFormatter(
                    commentCreatedAt,
                    'MM/DD/YYYY hh:mm:ss'
                  )}
                >
                  {moment(commentCreatedAt).fromNow()}
                </span>
              </div>
              <div>
                {commentCompletedAt && (
                  <span title="Completed" className="rounded-circle">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </span>
                )}
              </div>
              <Badge
                onClick={() => handleTimeMoveByComment(comment)}
                pill
                className="my-1 py-1 px-2 cursor-pointer"
                color="primary"
              >
                {timeFormator(timeInSeconds || 0, playerState.formatorType)}
              </Badge>
              <p className="mb-2 video-player-comment">{commentText}</p>
            </div>
          </div>
          <hr className="m-0" />
        </div>
      );
    });
  };

  const handleBronzeReviewComplete = async () => {
    try {
      const { asset_comments: assetComments, project_folder: projectFolder, id } =
        playerData?.getReviewLinkDetails?.assetCommentDetails;
      setIsLoading(true);
      await videoPlayerReviewComplete({
        variables: {
          projectId: projectFolder?.project?.id ?? null,
          isMappedComments: !!assetComments?.length,
          folderAssetId: id
        },
      });
    } catch (err) {
      console.log('handleBronzeReviewComplete err', err);
    }
  };

  const renderReviewCompletedButton = (taskName, checkoutUserId = null) => {
    if (
      (playerState?.isBronze &&
        taskName === SECTION_TASKS.userCommentReview &&
        checkoutUserId === auth?.data?.userId) ||
      (playerState?.isBronze &&
        taskName &&
        BRONZE_REVIEW_COMPLETE_ROLES[taskName].includes(auth?.data?.role)) ||
      (!playerState?.isBronze &&
        !UNALLOWED_ROLES_FOR_VIDEO_REVIEW_COMPLETE.includes(auth?.data?.role))
    ) {
      return (
        <LoadingButton
          size="sm"
          color="primary"
          className="p-0 px-2"
          disabled={reviewCompleted || reviewStatus}
          onClick={() =>
            playerState?.isBronze
              ? setReviewCompleteStatus(true)
              : handleReivewChecked()
          }
        >
          <span className="font-size-12">Review completed</span>
        </LoadingButton>
      );
    }
    return null;
  };

  if (playerData?.getReviewLinkDetails?.assetCommentDetails) {
    const {
      asset_comments: assetComments,
      taskName,
      project_folder: {
        project: { checkoutUserId },
      },
    } = playerData.getReviewLinkDetails.assetCommentDetails;
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between p-3">
          <span className="font-size-14 font-weight-bolder">
            {assetComments.length} Comments
          </span>

          {renderReviewCompletedButton(taskName, checkoutUserId)}
        </div>
        <hr className="m-0" />

        <div className="comment-list">
          {commentLoading && <Spinner color="primary" className="spinner" />}
          {renderComments(assetComments)}
        </div>
        {reviewCompleteStatus && (
          <ReviewCompleteModal
            isLoading={isLoading}
            handleReivewComplete={handleBronzeReviewComplete}
            setReviewComplete={setReviewCompleteStatus}
            reviewCompleteStatus={reviewCompleteStatus}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <h6 className="p-3 m-0 ">0 Comments</h6>
      <hr className="mt-0" />

      <div className="comment-list">
        {false && <Spinner color="primary" className="spinner" />}

        <div className="text-center">
          <b>Not comments to display</b>
        </div>
      </div>
    </div>
  );
};

export default ListComments;
