import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import Logo from 'assets/videoms-logo.png';
import HeaderUserDropdown from 'common/HeaderUserDropdown';
import PilotProjectListing from 'pages/Pilot/components/PilotProjectListing';
import RBAC from 'hoc/RBAC';
import { ROLES } from 'constants/role';
import PortalDropdown from 'common/PortalDropdown';

const Header = ({
  pilotHeader,
  handleSort,
  selectProject,
  projects,
  pilotRegistration,
  portalName,
}) => {
  return (
    <header className="border-bottom">
      <Navbar className="py-1 pr-0" expand="md">
        <div>
          <NavbarBrand
            href="https://videoms.com/"
            target="_blank"
            tag="a"
            className="d-inline-flex flex-column"
          >
            <img src={Logo} alt="Logo" width="80" />
            {portalName && (
              <span className="font-size-12 text-right text-secondary font-weight-normal">
                {portalName}
              </span>
            )}
          </NavbarBrand>
        </div>

        {!pilotRegistration && pilotHeader && (
          <PilotProjectListing
            handleSort={handleSort}
            selectProject={selectProject}
            projects={projects}
          />
        )}

        <div className="align-items-center d-flex ml-auto position-relative">
          {!pilotRegistration && (
            <>
              <RBAC
                allowedRoles={[ROLES.VIDEOMS_PM, ROLES.ADMIN, ROLES.VIDEOMS_QA, ROLES.VIDEOMS_QC]}
              >
                <PortalDropdown />
              </RBAC>
            </>
          )}
          {!pilotRegistration && (
            <div className="d-flex align-items-center">
              <HeaderUserDropdown />
            </div>
          )}
        </div>
      </Navbar>
    </header>
  );
};

export default Header;
