import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

import Logo from 'assets/videoms-logo.png';
import { useMutation } from '@apollo/client';
import { authContext } from 'contexts/AuthContext';
import LoginForm from 'components/LoginForm';
import { ROLES } from 'constants/role';
import { extractSubDomain } from 'utils/helper';
import {
  CREDENTIAL_STATUS,
  INVALID_EMAIL,
  INVALID_PASSWORD,
} from 'constants/appConstants';
import { LOGIN as MUTATION_LOGIN } from './LoginGraphQL';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = ({ location }) => {
  const history = useHistory();
  const subDomain = extractSubDomain();
  const { setAuthData, auth } = useContext(authContext);
  const [credentialStatus, setCredentialStatus] = useState({
    CREDENTIAL_STATUS,
  });

  const { from } = location.state || {
    from: { pathname: '/crexi-search/results' },
  };

  const redirectBasedOnRole = (role, isEditorProfileUpdated) => {
    if (subDomain !== process.env.REACT_APP_VIDPITCH_KEYWORD) {
      if (role === ROLES.OPERATOR) {
        history.push('/brokers-directory/update-missing-details');
      } else if (role === ROLES.VIDEOMS_PM || role === ROLES.VIDEOMS_QC) {
        history.push('/pm-portal/projects/project-list');
      } else if (role === ROLES.VIDEOMS_EDITOR && isEditorProfileUpdated) {
        history.push('/editor-portal/projects/project-list');
      } else if (role === ROLES.VIDEOMS_EDITOR && !isEditorProfileUpdated) {
        history.push('/editor-create-profile');
      } else if (role === ROLES.VIDEOMS_SALES_PERSON) {
        history.push('/sales-portal/deals/');
      } else if (role === ROLES.VIDEOMS_PILOT) {
        const { pathname } = from;
        if (pathname.includes('pilot-dashboard')) {
          history.push(from);
        } else {
          history.push(`/pilot-dashboard/${0}`);
        }
      } else {
        const { pathname } = from;
        if (pathname.includes('logout')) {
          history.push('/crexi-search/results');
        } else {
          history.push(from);
        }
      }
    } else {
      history.push('/assets-listing');
    }
  };

  useEffect(() => {
    if (auth.data) {
      const { role, isEditorProfileUpdated } = auth.data;
      redirectBasedOnRole(role, isEditorProfileUpdated);
    }
  }, [auth]);

  const [login] = useMutation(MUTATION_LOGIN, {
    onCompleted: async ({ login }) => {
      const { role, isEditorProfileUpdated } = login;
      await setAuthData(login);
      redirectBasedOnRole(role, isEditorProfileUpdated);
      setCredentialStatus({ CREDENTIAL_STATUS });
    },
    onError: ({ message }) => {
      if (message === INVALID_EMAIL) {
        setCredentialStatus({
          invalidValidPassword: false,
          invalidValidEmail: true,
        });
      } else if (message === INVALID_PASSWORD) {
        setCredentialStatus({
          invalidValidEmail: false,
          invalidValidPassword: true,
        });
      } else {
        toast.error(message);
      }
    },
  });

  const handleLogin = async (values, actions) => {
    try {
      await login({
        variables: values,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <div className="no-auth-main-container">
      <Helmet
        defaultTitle={
          subDomain !== process.env.REACT_APP_VIDPITCH_KEYWORD
            ? 'Login-VidTech'
            : 'Vidpitch'
        }
      />
      <div className="form-signin">
        <Card className="w-100 border-radius-8px">
          <CardBody className="p-4">
            <div className="mb-4">
              <img src={Logo} alt="Logo" width="100" />
            </div>
            {process.env.REACT_APP_REGION_DUMMY}
            <Formik
              initialValues={initialValues}
              onSubmit={handleLogin}
              validationSchema={validationSchema}
              children={(props) => (
                <LoginForm {...props} credentialStatus={credentialStatus} />
              )}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Login;
